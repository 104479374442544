import { Button } from 'react-bootstrap'
import React from 'react'
import { IoIosArrowRoundForward } from "react-icons/io";
const CustomOrder = () => {
    return (
        <div className='customOrder'>
            <div className='customOrderOverLay'>
                <h2 data-aos="slide-left" data-aos-duration="1000">Custom Order</h2>
                <Button size="sm" data-aos="slide-right" data-aos-duration="1000">
                    <IoIosArrowRoundForward />
                </Button>
            </div>
        </div>
    )
}

export default CustomOrder