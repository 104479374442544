import React from 'react'

const Bedcrum = ({ title, active }) => {
    return (
        <div className='bedcurm'>
            <ul>
                <li>Home</li>
                {active ?
                    <li className="active">{active}</li>
                    : ''
                }
            </ul>
            <h2 data-aos="slide-right" data-aos-duration="1000">{title}</h2>
        </div>
    )
}

export default Bedcrum